export const english = {
    generalErrorMessage: {
        fieldIsRequired: 'field is required',
    },
    //Login Process Messages
    authErrorMessage: {
        emailError: 'The email field must be a valid email',
        passwordValid: 'The password field must be at least 8 characters',
        validPassRegex: 'Passwords must be at least 8 characters long, and contain at least 1 alphabet,1 uppercase, 1 numeric, and 1 special character',
        confirmPasswordValid: 'The confirm password confirmation does not match',
        validCharactersfirst: 'The first name field may only contain alphabetic characters as well as spaces',
        validCharacterslast: 'The last name field may only contain alphabetic characters as well as spaces',
        minMust: 'must be at least 3 characters',
    },
}
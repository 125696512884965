// IMPORTANT NOTE: 

// IF YOU HAVE CHANGES IN THIS FILE, PLEASE VERIFY THE CHANGES BECAUSE THIS FILE IS CONNECTED TO THE PAYMENT MODULE.
// AND YOUR CHANGES ARE REQUIRED. ALSO ADD YOUR CHANGES TO THE 'CHARGEBEE-SETUP' AND 'PADDLE-SETUP' FOLDER.

import { createRouter, createWebHashHistory } from 'vue-router'
import auth from './auth'
import projects from './projects'
import chat from './chat'
import settings from './settings'
import timesheet from './timesheet'
import milestonesheet from './milestonesheet'
// <!-- Start Remove Section Payment -->
import payment from './payment';
// <!-- End Remove Section Payment -->
// import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { useCustomComposable } from '@/composable'
import { App } from "realm-web";
const routes = [
	...auth,

	// PROJECT ROUTES
	...projects,

	// PROJECT ROUTES
	...chat,

	// SETTINGS ROUTES
	...settings,
	
	// TIMESHEET ROUTES
	...timesheet,

	//MILESTONE ROUTES
	...milestonesheet,

	// <!-- Start Remove Section Payment -->
	// PAYMENTS ROUTES
	...payment,
	// <!-- End Remove Section Payment -->
	{
		path: "/",
		name: "Home",
		component: () => import(/* webpackChunkName: "home" */ '@/views/Home'),
		meta: {
			title: 'Home',
			requiresAuth: true,
		}
	}, {
		path: "/:catchAll(.*)",
		name: "404",
		component: () => import(/* webpackChunkName: "404" */ '@/views/NotFound'),
		meta: {
			title: '404'
		}
	},
	{
		path: "/:cid/support/:pid/:cuid",
		name: "Support",
		meta: {
            title: "Support",
            requiresAuth: false,
			hideHeader: true,
            preventAdvanceSearch: true
        },
		component: () => import(/* webpackChunkName: "project" */ '@/views/Support/Support')
	},
	// <!-- Start Remove Section Payment -->
	{
		path: "/checkout",
		name: "Checkout",
		meta: {
            title: "Checkout",
            // requiresAuth: false,
			hideHeader: true,
            preventAdvanceSearch: true
        },
		component: () => import(/* webpackChunkName: "project" */ '@/views/Checkout/CheckoutPage.vue')
	}
	// <!-- End Remove Section Payment -->
]

const router = createRouter({
	history: createWebHashHistory(process.env.BASE_URL),
	routes
})

// const authInst = getAuth();
const {setTitle} = useCustomComposable()
router.beforeEach((to, _, next) => {
	const app = new App({ id: process.env.VUE_APP_MONGO_APP_ID });
	let user = app.currentUser;
	// onAuthStateChanged(authInst, (user) => {
		// CHECK META FOR AUTH REQUIRED
		// const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
		const requiresAuth = to.meta.requiresAuth;

		// SET PAGE TITLE
		setTitle({title: to.meta.title, prefix: "Alian Hub | "});

		if(user === null && requiresAuth === true) {
			// IF USER IS NOT LOGGED IN AND REQUESTS AUTH REQUIRED PAGE
			next({name: 'Log-in', query: {redirect_url: window.location.pathname}});
			return;
		} else if(user !== null && requiresAuth === false) {
			// IF USER IS LOGGED IN AND REQUESTS NO AUTH REQUIRED PAGE
			if(to.meta.title === 'Support'){
				next();
			}else{
				next({name: "Home"});
			}
			return;
		} else {
			next();
			return;
		}
	// })
})

export default router;

<template>
    <div>
        <!-- <div class="d-flex align-items-center justify-content-between">
            <h4 class="task-details-subtitle"></h4>
            <img :src="addIcon" alt="addIcon" class="cursor-pointer" @click="createSubTask = true">
        </div> -->
        <div class="overflow-auto style-scroll mobile__bg--withPadding mt-10px">
            <div class="d-flex p-1 position-re subtask-mobile-width subtask__wrapper pl-0">
                <div class="d-flex align-items-center justify-content-between subtask__title w-100">
                    <span :class="{'font-size-16 font-weight-600' : clientWidth <=767 , 'font-size-14 font-weight-700' : clientWidth > 767 }" class="font-weight-700 font-size-14">{{$t('ProjectDetails.subtask')}}</span>
                    <!-- ({{task.subTasks || 0}}) -->
                </div>
                <div class="d-flex align-items-center justify-content-between table-span-wrapper w-100">
                    <span class="font-size-12 font-weight-500 gray81">{{$t('Header.Chat')}}</span>
                    <span class="font-size-12 font-weight-500 gray81" v-if="checkPermission('task.task_assignee',project?.isGlobalPermission) !== null">{{$t('ProjectDetails.assignee')}}</span>
                    <span class="font-size-12 font-weight-500 gray81" v-if="checkPermission('task.task_due_date',project?.isGlobalPermission) !== null">{{$t('Projects.due_date')}}</span>
                    <span class="font-size-12 font-weight-500 gray81" v-if="checkPermission('task.task_priority',project?.isGlobalPermission) !== null && checkApps('Priority')">{{$t('Projects.priority')}}</span>
                    <span class="font-size-12 font-weight-500 gray81" v-if="checkPermission('task.task_status',project?.isGlobalPermission) !== null">{{$t('Projects.status')}}</span>
                    <img v-if="checkPermission('task.sub_task_create',project?.isGlobalPermission) === true" :src="addIcon" alt="addIcon" class="cursor-pointer add_subtask_list position-ab" @click="createSubTask = true">
                </div>
            </div>
            <div v-if="task.subTasks" class="overflow-y-auto style-scroll border-bottom subtask-mobile-width sub__task-item" @scroll="handleScroll">
                <SubTaskItem
                    v-for="(subTask) in subTasks" :key="'taskdetail-'+subTask._id"
                    class="d-flex align-items-center justify-content-between px-1 border-bottom subtask__item-input"
                    :task="subTask"
                    :project="project"
                    :parentAssignee="parentAssignee"
                />
            </div>
            <div v-else class="red text-center font-size-11 py-10px">
                {{$t('Projects.no_sub_tasks_found')}}
            </div>
        </div>
        <CreateTask
            v-if="createSubTask"
            :sprint="{...task.sprintArray, id: task.sprintId, folderId: task.folderObjId}"
            :taskId="task._id"
            :project="project"
            :assigneeOptions="task.AssigneeUserId"
            @cancel="createSubTask = false"
            class="bg-white m-0"
        />
    </div>
</template>

<script setup>
// PACKAGES
import {defineProps, inject, onMounted, ref} from "vue";
import { dbCollections } from "@/utils/FirebaseCollections";
import * as mongoQuery from "@/utils/MongoQueries/crudOperations/crudOperations"

// COMPONENTS
import SubTaskItem from "@/components/molecules/SubTaskItem/SubTaskItem.vue"
import CreateTask from "@/components/atom/CreateTask/CreateTask.vue"
import { useCustomComposable } from "@/composable";
import { mongodbSnapshot } from "@/utils/MongoQueries/crudOperationsWithSanpshot/crudOperationsWithSanpshot";

// UTILS
const {checkPermission, checkApps} = useCustomComposable();

// IMAGES
const addIcon = require("@/assets/images/black_plus.png");

// PROPS
const props = defineProps({
    task: {
        type: Object,
        required: true
    },
    parentAssignee: {
        type: Array,
        default: () => []
    }
})

const createSubTask = ref(false);
const subTasks = ref([]);
const clientWidth = inject("$clientWidth");
const project = inject("selectedProject");

onMounted(() => {
    getTask();
})

function getTask() {
    mongoQuery.mongodbCrudOperations({
        type: "find",
        collection: dbCollections.TASKS,
        data: [
            {
                ParentTaskId: props.task._id
            }
        ]
    })
    .then((response) => {
        response.forEach((task) => {
            const taskIndex = subTasks.value.findIndex((x) => x._id == task._id)
            if(!task?.deletedStatusKey) {
                if(taskIndex !== -1) {
                    subTasks.value[taskIndex] = {...subTasks.value[taskIndex], ...task}
                } else {
                    subTasks.value.push(task)
                }
            }
        })

        mongodbSnapshot({
            subCollection: dbCollections.TASKS,
            watchFilter: {
                filter: {
                    $or: [
                        {
                            operationType: "delete"
                        },
                        {
                            operationType: {$in: ["insert", "update", "replace"]},
                            "fullDocument.ParentTaskId": props.task._id
                        }
                    ]
                }
            }
        }, ({ error, data, snap, type }) => {
            if(error) {
                console.error('Mongo error in the get project data', error);
            }

            snap.value = snap;

            switch (type) {
                case "insert": {
                    const { fullDocument } = data;
                    const taskIndex = subTasks.value.findIndex((x) => x._id == fullDocument._id)
                    if(!fullDocument?.deletedStatusKey) {
                        if(taskIndex !== -1) {
                            subTasks.value[taskIndex] = {...subTasks.value[taskIndex], ...fullDocument}
                        } else {
                            subTasks.value.push(fullDocument)
                        }
                    }
                    break;
                }
                case "update": {
                    const { fullDocument } = data;
                    const taskIndex = subTasks.value.findIndex((x) => x._id == fullDocument._id)
                    if(taskIndex !== -1) {
                        if(!fullDocument?.deletedStatusKey) {
                            subTasks.value[taskIndex] = {...subTasks.value[taskIndex], ...fullDocument}
                        } else {
                            subTasks.value.splice(taskIndex, 1);
                        }
                    } else {
                        subTasks.value.push(fullDocument)
                    }
                    break;
                }
                case "replace": {
                    const { fullDocument } = data;
                    const taskIndex = subTasks.value.findIndex((x) => x._id == fullDocument._id)
                    if(taskIndex !== -1) {
                        if(!fullDocument?.deletedStatusKey) {
                            subTasks.value[taskIndex] = {...subTasks.value[taskIndex], ...fullDocument}
                        } else {
                            subTasks.value.splice(taskIndex, 1);
                        }
                    } else {
                        subTasks.value.push(fullDocument)
                    }
                    break;
                }
                case "delete": {
                    const { documentKey } = data;
                    const taskIndex = subTasks.value.findIndex((x) => x._id == documentKey._id.toString())
                    if(taskIndex !== -1) {
                        subTasks.value.splice(taskIndex, 1);
                    }
                    break;
                }
            }
        })
    })
}
</script>

<style>
@import './style.css';
</style>